<template>
  <sgv-table
    :items="orderMapped"
    :headers="headers"
    :filter.sync="filter"
    :toolbars="toolbars"
    :options.sync="options">

    <template slot="option" v-if="options.toolbar === 'itemData'">
      <DetailOrderItemInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId">
      </DetailOrderItemInput>

      <DetailOrderServiceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId">
      </DetailOrderServiceInput>

      <DetailOrderExpenseInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :configs="configs">
      </DetailOrderExpenseInput>

      <DetailOrderDiscountPriceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :configs="configs">
      </DetailOrderDiscountPriceInput>

      <DetailOrderVatPriceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :configs="configs"
        :orders="orders">
      </DetailOrderVatPriceInput>

      <DetailOrderNetPriceInput
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :contactId="formData.contactId"
        :configs="configs"
        :orders="orders">
      </DetailOrderNetPriceInput>
    </template>

    <template slot-scope="{item}">
      <DetailOrderRow
        :isVoid="formData.isVoid"
        :isDestroyable="!formData.approvedAt"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :item="item"
        @selected="selectedRow">
      </DetailOrderRow>

      <template v-if="selectedRows.includes(item.id)">
        <DetailOrderRowChild
          v-for="child in item.children"
          :key="child.id"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :orderId="item.id"
          :item="child">
        </DetailOrderRowChild>
      </template>
    </template>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import round from 'lodash/round'
import DetailOrderItemInput from './DetailOrderItemInput.vue'
import DetailOrderServiceInput from './DetailOrderServiceInput.vue'
import DetailOrderExpenseInput from './DetailOrderExpenseInput.vue'
import DetailOrderDiscountPriceInput from './DetailOrderDiscountPriceInput.vue'
import DetailOrderVatPriceInput from './DetailOrderVatPriceInput.vue'
import DetailOrderNetPriceInput from './DetailOrderNetPriceInput.vue'
import DetailOrderRow from './DetailOrderRow.vue'
import DetailOrderRowChild from './DetailOrderRowChild.vue'
import {
  LIST_ORDER, LIST_ORDER_CONFIG, CHECKOUT_REMAINING,
  WATCH_ORDER_CREATED, WATCH_ORDER_UPDATED, WATCH_ORDER_DESTROYED,
  WATCH_ORDER_CHILD_CREATED, WATCH_ORDER_CHILD_DESTROYED
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailOrder`,
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'qty', 'unit', 'price', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      configs: [],
      selectedRows: [],
      orders: []
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'network-only',
      skip () {
        return !(this.docId > 0)
      },
      subscribeToMore: [
        {
          document () {
            return WATCH_ORDER_CREATED(this.templateType)
          },
          variables () {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const newOrder = subscriptionData.data.orderCreated
            const orders = [
              ...previous.orders,
              newOrder
            ]

            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_UPDATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const updatedOrder = subscriptionData.data.orderUpdated
            const orders = [...previous.orders]

            const idx = orders.findIndex(v => v.id === updatedOrder.id)

            if (idx !== -1) {
              orders[idx] = updatedOrder
            }

            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_DESTROYED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const destroyedOrder = subscriptionData.data.orderDestroyed
            const orders = [...previous.orders]
            const idx = orders.findIndex(v => v.id === destroyedOrder.id)

            if (idx !== -1) orders.splice(idx, 1)

            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_CHILD_CREATED(this.templateType)
          },
          variables () {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const newOrder = subscriptionData.data.orderCreated
            const orders = [...previous.orders]
            const idx = orders.findIndex(v => v.id === newOrder.parentId)

            if (idx !== -1) {
              delete newOrder.children
              orders[idx].children.push(newOrder)
            }

            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_CHILD_DESTROYED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const destroyedOrder = subscriptionData.data.orderDestroyed
            const orders = [...previous.orders]
            const idx = orders.findIndex(v => v.id === destroyedOrder.parentId)

            if (idx !== -1) {
              const chIdx = orders[idx].children.findIndex(v => v.id === destroyedOrder.id)
              if (chIdx !== -1) {
                orders[idx].children.splice(chIdx, 1)
              }
            }

            return {...previous, orders}
          }
        }
      ]
    },
    configs: {
      query () {
        return LIST_ORDER_CONFIG(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          contactId: this.formData.contactId
        }
      },
      fetchPolicy: 'network-only'
    },
  },
  computed: {
    headers () {
      return [
        {text: 'ID', value: 'id'},
        {text: 'รายละเอียด', value: 'name'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'ราคา/หน่วย', value: 'price'},
        {text: `ราคา (${this.ledgersSum})`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      let arr = []

      if (!this.formData.approvedAt && this.docId > 0) {
        arr.push({value: 'itemData', icon: 'plus', class: 'text-success'})
      }
      if (!this.formData.closedAt && this.formData.approvedAt) {
        arr.push({icon: 'retweet', class: 'text-warning', func: this.checkoutRemaining})
      }
      return arr
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    },
    ledgersSum () {
      const total = this.orders
      .flatMap(order => order.children)
      .flatMap(child => child.ledgers)
      .reduce((t, ledger) => t += +ledger.amount, 0)

      return round(total, 2)
    },
    orderMapped () {
      return [
        ...this.orders.filter(v => v.type === 'item'),
        ...this.orders.filter(v => v.type === 'service'),
        ...this.orders.filter(v => v.type === 'expense'),
        ...this.orders.filter(v => v.type === 'discountPrice'),
        ...this.orders.filter(v => v.type === 'vatPrice'),
        ...this.orders.filter(v => v.type === 'netPrice'),
      ]
    }
  },
  methods: {
    selectedRow (id) {
      const idx = this.selectedRows.findIndex(v => v === id)
      if (idx !== -1) {
        this.selectedRows.splice(idx, 1)
      } else {
        this.selectedRows.push(id)
      }
    },
    checkoutRemaining () {
      this.$apollo.mutate({
        mutation: CHECKOUT_REMAINING(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    isComplete (orders) {
      const parentTotal = orders
      .filter(v => v.type === 'netPrice' && !v.parentId)
      .reduce((t,v) => t += v.totalPrice, 0)

      const childrenTotal = orders
      .filter(v => v.type === 'netPrice' && !v.parentId)
      .flatMap(v => v.children)
      .filter(v => v.docId !== this.docId && v.doc.closedAt)
      .reduce((t,v) => t += v.totalPrice, 0)

      return round(parentTotal - childrenTotal, 2) == 0
    },
    isBlank (orders) {
      const childrenTotal = orders
      .filter(v => !v.parentId)
      .flatMap(v => v.children)
      .reduce((t,v) => t += v.totalPrice, 0)

      return round(childrenTotal, 2) == 0
    },
    isOrderValid (orders) {
      const itemTotal = orders
      .filter(v => v.type !== 'netPrice' && !v.parentId)
      .reduce((t,v) => t += v.totalPrice, 0)

      const netPriceTotal = orders
      .filter(v => v.type === 'netPrice' && !v.parentId)
      .reduce((t,v) => t += v.totalPrice, 0)

      return this.orders.length > 0 && round(itemTotal - netPriceTotal, 2) == 0
    },
  },
  watch: {
    'formData.approvedAt': {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.options.toolbar = ''
        }
      }
    },
    orders (value) {
      this.$emit('update:isComplete', this.isComplete(value))
      this.$emit('update:isBlank', this.isBlank(value))
      this.$emit('update:isOrderValid', this.isOrderValid(value))
    }
  },
  components: {
    DetailOrderItemInput,
    DetailOrderServiceInput,
    DetailOrderExpenseInput,
    DetailOrderDiscountPriceInput,
    DetailOrderVatPriceInput,
    DetailOrderNetPriceInput,
    DetailOrderRow,
    DetailOrderRowChild
  }
}
</script>

<style lang="css">
</style>
