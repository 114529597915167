<template>
  <div class="row">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-date
          label="วันที่อนุมัติ"
          v-model="formData.approvedDate"
          class="col-12 col-md-4"
          :validations="[
            {text: 'required!', value: $v.formData.approvedDate.$dirty && $v.formData.approvedDate.$error}
          ]">
        </sgv-input-date>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { APPROVE_DOC } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    deliveredAt: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      formData: {
        approvedDate: this.$date.current().date
      }
    }
  },
  validations: {
    formData: {
      approvedDate: {required}
    }
  },
  methods: {
    approveData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: APPROVE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          approvedDate: this.formData.approvedDate,
          deliveredAt: this.$date.format(this.deliveredAt).utc
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.$toasted.global.success("จบสำเร็จ")
      })
      .catch(err => {
        console.log(JSON.stringify(err,null,2))
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
