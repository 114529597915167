<template>
  <div class="col-12">
    <div class="form-row">
      <sgv-input-select
        class="col-12"
        label="Email"
        :options="emails"
        select="id"
        placeholder="เลือก Email..."
        v-model="formData.deviceId"
        :validations="[
          {text: 'required!', value: $v.formData.deviceId.$dirty && $v.formData.deviceId.$error}
        ]">
        <template slot-scope="option">
          {{option.name}}
        </template>
      </sgv-input-select>

      <PrintEmailAddress
        class="col-12 col-sm-6"
        label="ผู้ติดต่อ"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :addressType="addressType"
        select="id"
        placeholder="เลือกผู้ติดต่อ..."
        v-model="formData.addressId"
        @change="addresses = $event"
        :validations="[
          {text: 'required!', value: $v.formData.addressId.$dirty && $v.formData.addressId.$error}
        ]">
        <template slot-scope="option">
          {{option.name}}
        </template>
      </PrintEmailAddress>

      <sgv-input-text
        disabled
        class="col-6"
        label="Email"
        :value="emailAddr">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-6 col-sm-12 col-md-4"
        label="ชื่อ"
        :value="attendance">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-6 col-md-4"
        label="โทร."
        :value="telephone">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-6 col-md-4"
        label="มือถือ"
        :value="mobile">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-text
        class="col-12"
        label="หัวข้อ"
        v-model="formData.subject"
        :validations="[
          {text: 'required!', value: $v.formData.subject.$dirty && $v.formData.subject.$error}
        ]">
      </sgv-input-text>

      <sgv-input-textarea
        class="col-12"
        label="เนื้อหา"
        :rows="3"
        auto-glow
        v-model="formData.text"
        :validations="[
          {text: 'required!', value: $v.formData.text.$dirty && $v.formData.text.$error}
        ]">
      </sgv-input-textarea>
    </div>

    <button
      type="button"
      class="btn btn-success mb-3"
      @click="sendEmail">
      ส่ง Email
    </button>

    <PrintEmailAttachment
      :docId="docId"
      :docType="docType"
      :templateType="templateType"
      v-model="formData.attachments">
    </PrintEmailAttachment>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import PrintEmailAttachment from './PrintEmailAttachment'
import PrintEmailAddress from './PrintEmailAddress'
import {
  LIST_EMAIL,
  COMPOSE_EMAIL,
  SEND_EMAIL
} from '../graph/email'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    addressType: {
      type: String,
      required: true
    }
  },
  validations: {
    formData: {
      deviceId: { required },
      addressId: { required },
      text: { required },
      subject: { required }
    }
  },
  data () {
    return {
      emails: [],
      formData: {
        deviceId: null,
        addressId: null,
        subject: '',
        text: '',
        attachments: []
      },
      addresses: [],
      emailAddr: '',
      attendance: '',
      telephone: '',
      mobile: ''
    }
  },
  apollo: {
    emails: {
      query () {
        return LIST_EMAIL(this.templateType)
      },
      variables () {
        return {docType: this.docType}
      },
      result (res) {
        const mails = res.data.emails
        if (mails.length === 1) {
          this.formData.deviceId = mails[0].id
        }
      }
    },
    email: {
      query () {
        return COMPOSE_EMAIL(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId,
          addressId: this.formData.addressId
        }
      },
      skip () {
        return !this.formData.addressId
      },
      result (res) {
        const {subject, text} = res.data.email
        this.formData.subject = subject
        this.formData.text = text

        const child = this.addresses.find(v => v.id === this.formData.addressId)
        if (child) {
          this.attendance = child.attendance
          this.telephone = child.telephone
          this.mobile = child.mobile
          this.emailAddr = child.email
        }
      },
      debounce: 250,
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    sendEmail () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = {
        subject: this.formData.subject,
        text: this.formData.text,
        attachments: this.formData.attachments
      }

      this.$apollo.mutate({
        mutation: SEND_EMAIL(this.templateType),
        variables: {
          docType: this.docType,
          deviceId: this.formData.deviceId,
          addressId: this.formData.addressId,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success("ส่ง email...")
      })
      .catch(this.$toasted.global.error)
    }
  },
  watch: {
    'formData.addressId': {
      handler (v) {
        if (!v) {
          this.$v.$reset()
          this.formData.subject = ""
          this.formData.text = ""
          this.attendance = ""
          this.telephone = ""
          this.mobile = ""
          this.emailAddr = ""
        }
      }
    }
  },
  components: {
    PrintEmailAttachment,
    PrintEmailAddress
  }
}
</script>

<style lang="css" scoped>
</style>
