<template>
  <tr>
    <td class="no-wrap">
      <fa
        v-if="isDestroyable"
        icon="trash"
        class="text-danger pointer mr-1"
        @click="destroyOrder(item.id)">
      </fa>

      <DetailOrderRowModal
        v-if="!isDestroyable && !isComplete && !isVoid"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :orderId="item.id"
        :orderType="item.type"
        :title="item.name">
      </DetailOrderRowModal>

      <span @click="emitSelected(item.id)" class="pointer">
        {{item.id}}
      </span>
    </td>
    <td class="pre-line">
      {{item.name}}
      <div v-if="item.inventory">
        <small class="text-primary">
          {{item.inventory.code}} ({{item.inventory.name}})
        </small>
      </div>
    </td>
    <td>
      <span v-if="['item', 'service'].includes(item.type)">
        <span :class="{'text-success': item.qty > 0, 'text-danger': item.qty < 0}">
          {{item.qty | comma}}
        </span>
        <span>{{item.unit.name}}</span>
        <span v-if="item.unitRatio !== 1" class="text-info">
          ({{item.qty * item.unitRatio | comma}})
        </span>
      </span>
      <span v-else>
        -
      </span>
      <DetailOrderUpdateModal
        class="ml-1"
        v-if="isDestroyable"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :orderId="item.id"
        :orderType="item.type"
        :title="item.name"
        :item="item">
      </DetailOrderUpdateModal>
    </td>
    <td class="text-right">
      <span v-if="item.price > 0">{{item.price | comma}}</span>
      <span v-else>-</span>
      <div v-if="item.discount" class="text-primary">
        <small>{{item.discount}} ({{item.discountPrice}})</small>
      </div>
    </td>
    <td
      class="text-right"
      :class="{'text-danger': item.totalPrice < 0}">
      {{item.totalPrice | comma}}
    </td>
  </tr>
</template>

<script>
import DetailOrderRowModal from './DetailOrderRowModal'
import DetailOrderUpdateModal from './DetailOrderUpdateModal'
import { DESTROY_ORDER } from './graph'
import round from 'lodash/round'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isDestroyable: {
      type: Boolean,
      required: true
    },
    isVoid: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    isComplete () {
      const curAmount = this.item.children.reduce((t,v) => t += +v.totalPrice, 0)
      return round(this.item.totalPrice - curAmount, 2) === 0
    }
  },
  methods: {
    destroyOrder (id) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitSelected (id) {
      this.$emit('selected', id)
    }
  },
  components: {
    DetailOrderRowModal,
    DetailOrderUpdateModal
  }
}
</script>

<style lang="css" scoped>
</style>
