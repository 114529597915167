<template>
  <div class="row mt-3">
    <template v-if="isAddressValid">
      <div class="col-12">
        <div class="form-row">
          <sgv-input-radio
            class="col-12"
            inline
            :options="types"
            select="value"
            v-model="selected">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-radio>
        </div>
      </div>

      <div class="col-12" v-if="selected === 'DetailPrintPdf'">
        <div class="form-row">
          <sgv-input-radio
            label="รายละเอียดรายการ"
            class="col-12"
            inline
            :options="printOptions"
            select="value"
            v-model="display">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-radio>
        </div>
        <div class="form-row">
          <sgv-input-radio
            label="ราคา/หน่วย"
            class="col-12"
            inline
            :options="priceOptions"
            select="value"
            v-model="priceDisplay">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-radio>
        </div>
      </div>

      <component
        class="col-12"
        :is="selected"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :formData="formData"
        addressType="sales"
        :url="printUrl"
        :filename="`${formData.code}.pdf`">
      </component>
    </template>

    <template v-else>
      <div class="col-12">
        <span>ไม่พบค่าที่อยู่เริ่มต้น...</span>
      </div>
    </template>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DetailPrintPdf from '@/views/doc_core/components/PrintPdf'
import DetailPrintEmail from '@/views/doc_core/components/PrintEmail'

export default {
  mixins: [retainMixin],
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailPrint`,
      rFields: ['display', 'selected', 'priceDisplay'],
      types: [
        {text: 'Sample', value: 'DetailPrintPdf'},
        {text: 'Email', value: 'DetailPrintEmail'},
      ],
      selected: 'DetailPrintPdf',
      printOptions: [
        {text: 'ซัพพลายเออร์', value: 'name'},
        {text: 'รหัสภายใน', value: 'inventory'},
        {text: 'ซัพพลายเออร์ + รหัสภายใน', value: 'both'},
      ],
      priceOptions: [
        {text: 'ค่าเริ่มต้น', value: 'default'},
        {text: 'ราคา/หน่วย (กรณีสุทธิ)', value: 'absolute'},
      ],
      display: 'name',
      priceDisplay: 'default'
    }
  },
  computed: {
    isAddressValid () {
      return this.formData.contact?.addresses.find(v => {
        return v.type === 'main' && v.isDefault
      })
    },
    printUrl () {
      return `/doc/${this.docType}/${this.docId}/print/purchase?display=${this.display}&priceDisplay=${this.priceDisplay}`
    }
  },
  watch: {
    selected: 'setRetaining',
    display: 'setRetaining',
    priceDisplay: 'setRetaining',
  },
  components: {
    DetailPrintPdf,
    DetailPrintEmail,
  }
}
</script>

<style lang="css" scoped>
</style>
