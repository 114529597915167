<template>
  <div class="form-row">
    <div
      class="col-6 col-sm-4 col-md-3 pointer"
      v-for="(item, i) in items"
      :key="i"
      @click="emitInput(item.src)">
      <img
        :src="item.isImage ? item.src : ''"
        class="img-fluid"
        alt="...">
      <div style="word-wrap: break-word;">
        <span :class="{'text-success': value.includes(item.src)}">
          {{item.filename}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LIST_ATTACHMENT,
  DETAIL_ATTACHMENT
} from '../graph/attachment'


export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      items: []
    }
  },
  methods: {
    fetchList () {
      this.$apollo.query({
        query: LIST_ATTACHMENT(this.templateType),
        variables: {
          docType: this.docType,
          id: this.docId
        },
        fetchPolicy: 'network-only'
      }).then(res => {
        const arr = res.data.items.map(async filename => {
          const {src, isImage} = await this.fetchCover(filename)
          return {src, filename, isImage}
        })
        Promise.all(arr).then(v => {
          this.items = v
        })
      })
      .catch(err => {
        this.$toasted.error(err)
      })
    },
    fetchCover (filename) {
      let extension = filename.split('.').pop()
      const isImage = ['jpg', 'jpeg', 'png', 'svg'].includes(extension.toLowerCase())

      return this.$apollo.query({
        query: DETAIL_ATTACHMENT(this.templateType),
        variables: {
          docType: this.docType,
          id: this.docId,
          filename
        },
        fetchPolicy: 'network-only'
      })
      .then(res => ({src: res.data.url, isImage}))
      .catch(() => ({src: '', isImage}))
    },
    emitInput (url) {
      const index = this.value.findIndex(v => v === url)
      const arr = [...this.value]
      if (index !== -1) {
        arr.splice(index, 1)
      } else {
        arr.push(url)
      }
      this.$emit('input', arr)
    }
  },
  created () {
    this.fetchList()
  }
}
</script>

<style lang="css">
</style>
