import gql from 'graphql-tag'


export const LIST_EMAIL = (templateType) => gql`query LIST_EMAIL ($docType: String!) {
  emails: listDoc${templateType}Email (docType: $docType) {
    id type code name
  }
}`

export const COMPOSE_EMAIL = (templateType) => gql`query COMPOSE_EMAIL ($docType: String!, $docId: Int!, $addressId: Int!) {
  email: composeDoc${templateType}Email (docType: $docType, docId: $docId, addressId: $addressId) {
    subject text
  }
}`

export const SEND_EMAIL = (templateType) => gql`mutation SEND_EMAIL ($docType: String!, $deviceId: Int!, $addressId: Int!, $input: EmailInput!) {
  sendMail: sendDoc${templateType}Email (docType: $docType, deviceId: $deviceId, addressId: $addressId, input: $input)
}`
