<template>
  <div class="form-row" v-if="options.length > 0">
    <sgv-input-select
      placeholder="ยอดรวม..."
      class="col-5 col-md-6"
      :options="options"
      select="id"
      v-model="formData.docConfigId"
      :validations="[
        {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
      ]">
      <template slot-scope="option">
        {{option.name}}
      </template>
    </sgv-input-select>

    <sgv-input-number
      class="col"
      placeholder="ราคา"
      v-model="formData.price"
      :precision="2"
      :validations="[
        {text: 'required!', value: $v.formData.price.$dirty && $v.formData.price.$error}
      ]"
      @keyup.enter.native="createOrder">
    </sgv-input-number>

    <div class="col-auto">
      <button class="btn btn-success" @click="createOrder">
        <fa icon="plus"></fa>
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import round from 'lodash/round'
import { CREATE_ORDER_NET_PRICE } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
    configs: {
      type: Array,
      default () {
        return []
      }
    },
    orders: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      formType: 'netPrice',
      formData: {
        docConfigId: null,
        price: 0
      }
    }
  },
  validations: {
    formData: {
      docConfigId: { required },
      price: { required },
    }
  },
  computed: {
    options () {
      return this.configs.filter(v => v.type === this.formType)
    }
  },
  methods: {
    calculatePrice () {
      const price =
      this.orders
      .filter(v => ['item', 'service', 'expense', 'discountPrice', 'vatPrice', 'netPrice'].includes(v.type))
      .reduce((t,v) => {
        const x = ['netPrice'].includes(v.type) ? -1 : 1
        return t + (v.totalPrice * x)
      }, 0)
      return round(price, 2)
    },
    serializeInput (input) {
      return input
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_ORDER_NET_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    setDefault () {
      this.formData.docConfigId = null
      this.formData.price = this.calculatePrice()
      this.$v.formData.$reset()
      const config = this.configs.find(v => v.type === this.formType && v.isDefault)
      if (config) this.formData.docConfigId = config.id
    }
  },
  watch: {
    'formData.docConfigId': {
      handler (v) {
        if (!v) {
          this.$v.formData.$reset()
        } else {
          this.formData.price = this.calculatePrice()
        }
      }
    },
    orders () {
      this.formData.price = this.calculatePrice()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setDefault()
    })
  }
}
</script>

<style lang="css" scoped>
</style>
