var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-row"},[_c('sgv-input-select',{staticClass:"col-12",attrs:{"label":"Email","options":_vm.emails,"select":"id","placeholder":"เลือก Email...","validations":[
        {text: 'required!', value: _vm.$v.formData.deviceId.$dirty && _vm.$v.formData.deviceId.$error}
      ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}]),model:{value:(_vm.formData.deviceId),callback:function ($$v) {_vm.$set(_vm.formData, "deviceId", $$v)},expression:"formData.deviceId"}}),_c('PrintEmailAddress',{staticClass:"col-12 col-sm-6",attrs:{"label":"ผู้ติดต่อ","docId":_vm.docId,"docType":_vm.docType,"templateType":_vm.templateType,"addressType":_vm.addressType,"select":"id","placeholder":"เลือกผู้ติดต่อ...","validations":[
        {text: 'required!', value: _vm.$v.formData.addressId.$dirty && _vm.$v.formData.addressId.$error}
      ]},on:{"change":function($event){_vm.addresses = $event}},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}]),model:{value:(_vm.formData.addressId),callback:function ($$v) {_vm.$set(_vm.formData, "addressId", $$v)},expression:"formData.addressId"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":"","label":"Email","value":_vm.emailAddr}}),_c('sgv-input-text',{staticClass:"col-6 col-sm-12 col-md-4",attrs:{"disabled":"","label":"ชื่อ","value":_vm.attendance}}),_c('sgv-input-text',{staticClass:"col-6 col-md-4",attrs:{"disabled":"","label":"โทร.","value":_vm.telephone}}),_c('sgv-input-text',{staticClass:"col-6 col-md-4",attrs:{"disabled":"","label":"มือถือ","value":_vm.mobile}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"label":"หัวข้อ","validations":[
        {text: 'required!', value: _vm.$v.formData.subject.$dirty && _vm.$v.formData.subject.$error}
      ]},model:{value:(_vm.formData.subject),callback:function ($$v) {_vm.$set(_vm.formData, "subject", $$v)},expression:"formData.subject"}}),_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"label":"เนื้อหา","rows":3,"auto-glow":"","validations":[
        {text: 'required!', value: _vm.$v.formData.text.$dirty && _vm.$v.formData.text.$error}
      ]},model:{value:(_vm.formData.text),callback:function ($$v) {_vm.$set(_vm.formData, "text", $$v)},expression:"formData.text"}})],1),_c('button',{staticClass:"btn btn-success mb-3",attrs:{"type":"button"},on:{"click":_vm.sendEmail}},[_vm._v(" ส่ง Email ")]),_c('PrintEmailAttachment',{attrs:{"docId":_vm.docId,"docType":_vm.docType,"templateType":_vm.templateType},model:{value:(_vm.formData.attachments),callback:function ($$v) {_vm.$set(_vm.formData, "attachments", $$v)},expression:"formData.attachments"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }