<template>
  <div class="form-row">
    <DetailOrderServiceInputItem
      ref="dropdownInventory"
      placeholder="บริการ..."
      class="col-5 col-md-6"
      select="id"
      :docType="docType"
      :templateType="templateType"
      :contactId="contactId"
      @change="recentPurchase"
      v-model="formData.docConfigId"
      :validations="[
        {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
      ]">
    </DetailOrderServiceInputItem>

    <sgv-input-text
      class="col"
      placeholder="จำนวน ราคา [ส่วนลด | 3%]"
      v-model="formData.price"
      :validations="[
        {text: 'required!', value: $v.formData.price.$dirty && $v.formData.price.$error}
      ]"
      @keyup.enter.native="createOrder">
    </sgv-input-text>

    <div class="col-auto">
      <button class="btn btn-success" @click="createOrder">
        <fa icon="plus"></fa>
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailOrderServiceInputItem from './DetailOrderServiceInputItem.vue'
import {
  CREATE_ORDER_SERVICE,
  DETAIL_ORDER_RECENT
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      formType: 'item',
      formData: {
        docConfigId: null,
        price: ''
      }
    }
  },
  validations: {
    formData: {
      docConfigId: { required },
      price: { required },
    }
  },
  methods: {
    serializeInput (input) {
      try {
        const obj = {docConfigId: input.docConfigId}
        const arr = input.price.trim().split(' ')
        if (arr.length === 1) {
          obj.qty = 1
          obj.price = Number(arr[0])
          obj.discount = ''
        } else if (arr.length === 2) {
          obj.qty = Number(arr[0])
          obj.price = Number(arr[1])
          obj.discount = ''
        } else if (arr.length === 3) {
          obj.qty = Number(arr[0])
          obj.price = Number(arr[1])
          obj.discount = arr[2]
        }
        return obj
      } catch (err) {
        this.$toasted.global.error('กรอกข้อมูลไม่ถูกต้อง')
      }
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_ORDER_SERVICE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$refs.dropdownInventory.setFocus()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    setDefault () {
      this.formData.docConfigId = null
      this.formData.price = ''
      this.$v.formData.$reset()
    },
    recentPurchase (config) {
      if (!config) return

      this.$apollo.query({
        query: DETAIL_ORDER_RECENT(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          inventoryId: config.inventoryId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        const {qty, price, discount} = res.data.item
        this.formData.price = `${qty} ${price} ${discount || ''}`
      })
      .catch(() => {})
    }
  },
  watch: {
    'formData.docConfigId': {
      handler (v) {
        if (!v) {
          this.formData.price = ''
          this.$v.formData.$reset()
        }
      }
    }
  },
  created () {
    this.setDefault()
  },
  components: {
    DetailOrderServiceInputItem
  }
}
</script>

<style lang="css" scoped>
</style>
