<template>
  <span class="">
    <fa
      icon="plus"
      class="text-success pointer mr-1"
      @click="showModal = true">
    </fa>

    <b-modal
      v-model="showModal"
      :title="title"
      hide-footer
      body-class="p-0">

      <div class="row px-3">
        <div class="col-12 pt-3">
          <div class="form-row" v-if="['item', 'service'].includes(orderType)">
            <sgv-input-number
              class="col-12"
              label="จำนวนรับเข้าสินค้า"
              v-model="qty"
              :precision="2">
            </sgv-input-number>
          </div>

          <div class="form-row" v-else>
            <sgv-input-currency
              class="col-12"
              label="ราคา"
              v-model="price">
            </sgv-input-currency>
          </div>

          <button
            type="button"
            class="btn btn-success mb-3"
            @click="checkoutOrder">
            เพิ่ม
          </button>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { CHECKOUT_ORDER } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    orderId: {
      type: Number,
      required: true
    },
    orderType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showModal: false,
      qty: 0,
      price: 0
    }
  },
  methods: {
    checkoutOrder () {
      this.$apollo.mutate({
        mutation: CHECKOUT_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: this.orderId,
          input: {
            qty: this.qty,
            price: this.price
          }
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
