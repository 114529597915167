<template>
  <div class="row">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-text
          label="สาเหตุการยกเลิก"
          v-model="remark"
          class="col-12"
          :validations="[
            {text: 'required!', value: $v.remark.$dirty && $v.remark.$error}
          ]">
        </sgv-input-text>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { VOID_DOC } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      remark: ''
    }
  },
  validations: {
    remark: { required }
  },
  methods: {
    voidData () {
      this.$v.remark.$touch()
      if (this.$v.remark.$invalid) return

      this.$apollo.mutate({
        mutation: VOID_DOC(this.templateType),
        variables: {
          docId: this.docId,
          docType: this.docType,
          remark: this.remark
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.$toasted.global.success("Void สำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
