<template>
  <sgv-input-select
    :options="items"
    @input="emitInput"
    :validations="validations"
    v-bind="$attrs"
    :value="value"
    select="salesCredit">
    <template slot-scope="option">
      {{option.salesCredit}}
    </template>
  </sgv-input-select>
</template>

<script>
import { LIST_SALES_CREDIT } from './graph'

export default {
  name: 'DetailFormSalesCreditInput',
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    }
  },
  data () {
    return {
      items: [],
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_SALES_CREDIT(this.templateType)
      },
      variables () {
        return {
          contactId: this.contactId,
          docId: this.docId,
          docType: this.docType
        }
      },
      fetchPolicy: 'network-only',
      result (res) {
        const arr = res.data.items
        const idx = arr.findIndex(v => v.id === 0)

        if (idx !== -1) {
          this.emitInput(arr[idx].salesCredit)
        } else {
          const isDefault = arr.find(v => v.isDefault)
          if (isDefault) {
            this.emitInput(isDefault.salesCredit)
          }
        }
      }
    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
