<template>
  <span class="">
    <fa
      icon="pencil-alt"
      class="text-warning pointer mr-1"
      @click="showModal = true">
    </fa>

    <b-modal
      v-model="showModal"
      :title="title"
      hide-footer
      body-class="p-0">

      <div class="row px-3">
        <div class="col-12 pt-3">
          <div class="form-row" v-if="['item', 'service'].includes(orderType)">
            <sgv-input-number
              class="col-12"
              label="จำนวน"
              :precision="2"
              v-model="qty">
            </sgv-input-number>
          </div>

          <div class="form-row" v-if="['item', 'service'].includes(orderType)">
            <sgv-input-currency
              class="col-12"
              label="มูลค่า (กรณีราคาสุทธิใส่เครื่อหมาย -)"
              :precision="2"
              minus
              v-model="totalPrice">
            </sgv-input-currency>
          </div>

          <div class="form-row" v-else>
            <sgv-input-currency
              class="col-12"
              label="มูลค่า"
              :precision="2"
              v-model="totalPrice">
            </sgv-input-currency>
          </div>

          <button
            type="button"
            class="btn btn-warning mb-3"
            @click="updateOrder">
            แก้ไข
          </button>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { UPDATE_ORDER } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    orderId: {
      type: Number,
      required: true
    },
    orderType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showModal: false,
      qty: this.item.qty,
      totalPrice: this.setTotalPrice(this.item)
    }
  },
  methods: {
    updateOrder () {
      this.$apollo.mutate({
        mutation: UPDATE_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: this.orderId,
          qty: this.qty,
          totalPrice: this.totalPrice
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.showModal = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    setTotalPrice (item) {
      if (['item', 'service'].includes(this.orderType)) {
        return item.price < 0 ? item.totalPrice * -1 : item.price
      }

      return item.totalPrice
    }
  }
}
</script>

<style lang="css" scoped>
</style>
